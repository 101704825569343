export function SuccessSvg() {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="39.668" cy="39.668" r="36.3623" fill="#38CB4A" />
            <mask id="mask0_38_2266" maskUnits="userSpaceOnUse" x="6" y="6" width="67" height="67">
                <rect x="6.61133" y="6.61133" width="66.1133" height="66.1133" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_38_2266)">
                <path
                    d="M33.5694 53.3354L21.8383 41.6004L24.8087 38.6261L33.5694 47.3869L55.1916 25.7686L58.162 28.739L33.5694 53.3354Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}
