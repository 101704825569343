export function StepperErrorIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_494_2799)">
                <circle cx="12" cy="12" r="12" fill="#F8374A" />
                <mask
                    id="mask0_494_2799"
                    maskUnits="userSpaceOnUse"
                    x="4"
                    y="4"
                    width="16"
                    height="16"
                >
                    <rect x="4" y="4" width="16" height="16" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_494_2799)">
                    <path
                        d="M8.26665 16.4331L7.56665 15.7331L11.3 11.9997L7.56665 8.26641L8.26665 7.56641L12 11.2997L15.7333 7.56641L16.4333 8.26641L12.7 11.9997L16.4333 15.7331L15.7333 16.4331L12 12.6997L8.26665 16.4331Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_494_2799">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
