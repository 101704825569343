import { Typography } from '@mui/material';

type Props = {
    value: string;
    maxLength: number;
};

export function MaxLengthCounter({ maxLength, value }: Props) {
    return (
        <Typography
            variant="body2"
            sx={{
                color: (theme) => theme.palette.grey[400],
                textAlign: 'right',
                width: '100%',
            }}
        >
            {value.length}/{maxLength}
        </Typography>
    );
}
