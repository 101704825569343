import { DockColor } from '@dock/common';

export function RightWireIcon() {
    return (
        <svg
            width="443"
            height="254"
            viewBox="0 0 443 254"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M47.9963 159.098L49.8185 164.891L15.4276 175.743C13.8637 176.232 12.2047 175.362 11.7015 173.811L11.6607 173.676C11.1712 172.112 12.0415 170.453 13.5918 169.949L47.9963 159.098Z"
                fill="#A6A6A6"
            />
            <path
                d="M38.2868 128.283L36.4646 122.49L2.07363 133.342C0.50979 133.831 -0.36054 135.504 0.142609 137.068L0.183403 137.204C0.672954 138.768 2.34561 139.638 3.90945 139.135L38.2868 128.283Z"
                fill="#A6A6A6"
            />
            <path
                d="M145.974 92.7092L156.608 126.42C159.587 135.858 154.351 145.907 144.914 148.885L130.159 153.536L108.755 85.6787L123.509 81.028C132.947 78.0499 142.996 83.2854 145.974 92.7092Z"
                fill="#E8E8E8"
            />
            <path
                d="M147.242 96.8154L155.27 122.261L188.276 111.847L180.248 86.4016L147.242 96.8154Z"
                fill="#E8E8E8"
            />
            <path
                d="M90.388 81.9046L117.271 167.111L132.873 162.189L105.99 76.9822L90.388 81.9046Z"
                fill="#E8E8E8"
            />
            <path
                d="M184.363 99.3589C313.088 58.7534 360.697 34.2894 395.183 66.8173C441.146 110.17 373.425 163 403.22 220.591C414.384 242.158 438.046 250.453 466.671 250.453"
                stroke="#009FE3"
                strokeWidth="6.79931"
                strokeMiterlimit="10"
            />
            <path
                d="M134.416 97.6864L143.35 126.012"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M126.46 100.202L135.395 128.528"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.2038 105.897L55.0305 181.416L115.738 162.262L91.9117 86.7435L31.2038 105.897Z"
                fill="#E8E8E8"
            />
            <path
                d="M41.6783 138.961L44.6561 148.403L75.0166 138.827L72.0387 129.386L41.6783 138.961Z"
                fill={DockColor.PRIMARY_500}
            />
            <path
                d="M80.293 100.732L86.8475 98.6654"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.8884 111.598L74.2143 102.65"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M117.295 167.134L90.4104 81.9254"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M106.022 77.0027L132.892 162.212"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M156.037 122.313L148.014 96.8567"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
            />
            <path
                d="M55.0539 181.44L31.2291 105.927"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M180.284 86.4266L188.307 111.883"
                stroke="#222222"
                strokeWidth="2.71973"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M250.262 37.934L215.98 72.1481"
                stroke="#B7EBEE"
                strokeWidth="5.43945"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M198.914 1L185.914 45.6715"
                stroke="#B7EBEE"
                strokeWidth="5.43945"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M221.854 150.734L256.885 176.382"
                stroke="#B7EBEE"
                strokeWidth="5.43945"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M281.267 103.547L228.287 105.995"
                stroke="#B7EBEE"
                strokeWidth="5.43945"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
        </svg>
    );
}
