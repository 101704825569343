import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react';

type CloseButtonProps = {
    onClick: (_: React.SyntheticEvent | Event, reason?: string) => void;
    testId?: string;
};

export function CloseButton({ onClick, testId = 'closeButton' }: CloseButtonProps) {
    return (
        <IconButton
            size="small"
            onClick={onClick}
            color="inherit"
            aria-label="close"
            data-testid={testId}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>
    );
}
