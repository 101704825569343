import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert as MuiAlert, type AlertColor, Snackbar, Typography } from '@mui/material';
import React from 'react';

import { CloseButton } from './CloseButton';
import { ContentWithTitle } from './ContentWithTitle';

type AlertProps = {
    title: string;
    handleCloseToast: (_: React.SyntheticEvent | Event, reason?: string) => void;
    severity?: AlertColor;
    description?: string | undefined;
    isOpen?: boolean;
    autoHideDuration?: number;
};

const vertical = 'bottom';
const horizontal = 'right';

export function Alert({
    autoHideDuration = 6000,
    description,
    handleCloseToast,
    isOpen = false,
    severity = 'success',
    title,
}: AlertProps) {
    const hasDescription = !!title && !!description;

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={autoHideDuration}
            onClose={handleCloseToast}
            sx={{ width: '400px' }}
            anchorOrigin={{ horizontal, vertical }}
            key={`${vertical}${horizontal}`}
            data-testid={`${severity}Alert`}
        >
            <MuiAlert
                severity={severity}
                icon={<ErrorOutlineIcon />}
                action={<CloseButton onClick={handleCloseToast} testId="closeAlert" />}
                sx={{ width: '100%' }}
            >
                {hasDescription ? (
                    <ContentWithTitle title={title} description={description} />
                ) : (
                    <Typography variant="body1">{title}</Typography>
                )}
            </MuiAlert>
        </Snackbar>
    );
}
