export function LeftWireIcon() {
    return (
        <svg
            width="439"
            height="286"
            viewBox="0 0 439 286"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M370.383 183.662L347.418 110.897C346.506 107.999 348.109 104.895 351.022 103.982L412.724 84.5185L439 167.803L377.298 187.267C374.385 188.179 371.295 186.561 370.383 183.662Z"
                fill="#E8E8E8"
            />
            <path
                d="M370.719 184.75L347.106 109.91L312.001 120.986L335.614 195.826L370.719 184.75Z"
                fill="#E8E8E8"
            />
            <path
                d="M328.234 172.425L319.38 144.364L282.984 155.847L291.838 183.909L328.234 172.425Z"
                fill="#E8E8E8"
            />
            <path
                d="M287.32 169.642C145.864 214.263 93.5485 241.142 55.6505 205.407C5.14439 157.769 79.5575 99.716 46.8234 36.4252C34.5536 12.7242 7.1305 4 -24 4"
                stroke="#009FE3"
                strokeWidth="7.35598"
                strokeMiterlimit="10"
            />
            <path
                d="M422.993 161.521L383.05 174.129C380.167 175.041 377.077 173.438 376.165 170.539L372.943 160.256"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M342.416 171.481L332.559 140.247"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M351.184 168.715L341.327 137.467"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M370.31 183.442L347.492 111.118"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M335.604 195.8L311.992 120.96"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M291.822 183.898L282.965 155.827"
                stroke="#222222"
                strokeWidth="2.94239"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M195.017 244.231L232.091 207.216"
                stroke="#B7EBEE"
                strokeWidth="5.88478"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M250.569 284.189L264.634 235.86"
                stroke="#B7EBEE"
                strokeWidth="5.88478"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M225.735 122.21L187.852 94.4489"
                stroke="#B7EBEE"
                strokeWidth="5.88478"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M161.474 173.246L218.777 170.613"
                stroke="#B7EBEE"
                strokeWidth="5.88478"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
        </svg>
    );
}
