import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Control, Controller } from 'react-hook-form';

type ControlledDesktopDatePickerTypes = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    inputFormat: string;
    label: string;
};

export function ControlledDesktopDatePicker({
    control,
    inputFormat,
    label,
    name,
}: ControlledDesktopDatePickerTypes) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <DesktopDatePicker
                        {...rest}
                        label={label}
                        inputFormat={inputFormat}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                FormHelperTextProps={{
                                    sx: { margin: 0, mt: 1 },
                                }}
                            />
                        )}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
