import { Box, Typography, SxProps, Theme } from '@mui/material';

type Props = {
    message: string;
    type: 'error' | 'info';
    sx?: SxProps<Theme>;
};

const boxStyles = { alignItems: 'left', display: 'flex', width: '100%' };

export function HelperText({ message, sx = {}, type = 'info' }: Props) {
    const color = type === 'error' ? 'error.main' : 'grey.600';

    return (
        <Box sx={{ ...boxStyles, ...sx }}>
            <Typography variant="body2" sx={{ color }}>
                {message}
            </Typography>
        </Box>
    );
}
