import { format, addMinutes } from 'date-fns';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATE_FORMAT_WITH_SECONDS = 'yyyy-MM-dd HH:mm:ss';
export const IDEMPOTENCY_KEY_DATE = 'yyyyMMdd';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_HOURS_AND_MINUTES = 'HH:mm';

export const transformDate = (
    date: Date | undefined | null,
    dateFormat = DATE_FORMAT_WITH_SECONDS
) => {
    if (!date) {
        return '';
    }

    return format(date, dateFormat);
};

export function formatUTCDate(date: Date) {
    return transformDate(addMinutes(date, date.getTimezoneOffset()), DATE_HOURS_AND_MINUTES);
}
