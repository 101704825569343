import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectProps,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState } from 'react';

import { DockColor } from '@dock/common';

import { HelperText } from './HelperText';

export type OptionType<TValue extends string> = {
    label: string;
    description?: string;
    value: TValue;
    isDisabled?: boolean;
    isHidden?: boolean;
};

type SingleSelectProps<T extends string | string[]> = {
    label: string;
    defaultOption?: T | undefined;
    options: OptionType<string>[];
    id: string;
    onChange: (value: T) => void;
    inputProps?: SelectProps;
    testId?: string;
    helperText?: string | undefined;
    isError?: boolean;
    disabled?: boolean;
    errorMessage?: string | undefined;
};

// TODO DU-1557 change the name to CustomSelect
export function SingleSelect<T extends string | string[]>({
    defaultOption,
    disabled = false,
    errorMessage,
    helperText,
    id,
    inputProps,
    isError,
    label,
    onChange,
    options,
    testId = 'singleSelect',
}: SingleSelectProps<T>) {
    const isMultiple = Array.isArray(defaultOption);
    const [selected, select] = useState<T | undefined>(defaultOption);

    const onChangeHandler = (event: SelectChangeEvent<unknown>) => {
        const {
            target: { value },
        } = event;

        if (isMultiple) {
            const parsedValue = typeof value === 'string' ? value.split(',') : value;
            onChange(parsedValue as T);
            select(parsedValue as T);
        } else {
            onChange(value as T);
            select(value as T);
        }
    };

    const labelId = `${id}-label`;

    const helperTextContent = errorMessage || helperText;

    return (
        <FormControl fullWidth error={!!isError}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                id={id}
                value={selected?.length ? selected : ['']}
                label={label}
                multiple={isMultiple}
                onChange={onChangeHandler}
                data-testid={testId}
                inputProps={{ 'data-testid': `${testId}Input` }}
                sx={{
                    '& .MuiInputBase-input': {
                        color: ({ palette }) => palette.grey[600],
                    },
                }}
                disabled={disabled}
                {...inputProps}
            >
                {options
                    .filter(({ isHidden = false }) => !isHidden)
                    .map(
                        ({
                            description,
                            isDisabled = false,
                            label: optionLabel,
                            value: optionValue,
                        }) => (
                            <MenuItem
                                disabled={isDisabled}
                                sx={{
                                    display: description ? 'block' : 'flex',
                                    height: '55px',
                                }}
                                key={optionLabel}
                                value={optionValue}
                                data-testid={`${optionValue}Option`}
                            >
                                {optionLabel}
                                {description && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: DockColor.NEUTRAL_600,
                                            marginTop: '4px',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {description}
                                    </Typography>
                                )}
                            </MenuItem>
                        )
                    )}
            </Select>
            {helperTextContent && (
                <HelperText
                    message={helperTextContent}
                    type={errorMessage ? 'error' : 'info'}
                    sx={{ marginTop: '8px' }}
                />
            )}
        </FormControl>
    );
}
