import React from 'react';

export const useHandleToast = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpenToast = () => {
        setIsOpen(true);
    };

    const handleCloseToast = (_?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    return { handleCloseToast, handleOpenToast, isOpen };
};
