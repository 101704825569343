import { Skeleton as MuiSkeleton } from '@mui/material';

interface SkeletonProps {
    variant?: 'text' | 'rounded' | 'rectangular' | 'circular';
    width?: number | string;
    height?: number | string;
}

/**
 * Component for skeletons with constrained width and height.
 * When you need skeleton for typography use native <Skeleton /> from @mui/material
 *
 * @export
 * @param {SkeletonProps} {
 *     height = '100px',
 *     variant = 'rounded',
 *     width = '100px',
 * }
 */
export function Skeleton({
    height = '100px',
    variant = 'rounded',
    width = '100px',
}: SkeletonProps) {
    return (
        <MuiSkeleton
            role="progressbar"
            variant={variant}
            animation="wave"
            width={width}
            height={height}
        />
    );
}
