import { DockColor } from '@dock/common';

type DockLogoIconProps = {
    color?: DockColor;
};

export function DockLogoIcon({ color = DockColor.WHITE }: DockLogoIconProps) {
    return (
        <svg
            data-testid="dockIcon"
            width="136"
            height="44"
            viewBox="0 0 136 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="full-name_typographic_h32px" clipPath="url(#clip0_511_23092)">
                <g id="Dock Financial_Typographic">
                    <g id="Group">
                        <g id="DOCK">
                            <path
                                id="Combined Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.23392 4.8309L3.63815 1.59787C3.75224 0.685032 4.52824 0 5.44828 0H5.44833H27.9733C31.394 0 34.0405 2.99777 33.6162 6.39158L33.6102 6.43919L31.196 25.7507L31.1859 25.8313C30.7294 29.4816 27.6261 32.2207 23.947 32.2207L15.9106 32.2024L14.3078 32.206L14.5051 30.5554L14.9098 27.3751L15.1112 25.7648H16.7108L21.5314 25.7729C22.452 25.7729 23.2291 25.0885 23.3454 24.1753L23.3481 24.1545L25.4004 8.0515V7.93797C25.4004 7.13298 24.7485 6.48 23.9434 6.47859L4.63191 6.44407L3.03261 6.4412L3.23392 4.8309ZM0.0113964 30.6082C-0.0948449 31.4578 0.554883 32.2025 1.41121 32.206H6.25357H7.86404L8.06973 30.5554L9.26295 20.9339L9.46365 19.3236H12.6846H15.8946H17.5269L17.7146 17.7133L18.1227 14.4921L18.3322 12.8824L16.7108 12.8824L3.8379 12.8818L2.22742 12.8818L2.02611 14.4921L0.0129566 30.5957L0.0113964 30.6082Z"
                                fill={color}
                            />
                            <path
                                id="Fill 5"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M59.6048 7.93803V8.0515L57.5651 24.1677C57.4486 25.0881 56.6637 25.7769 55.7359 25.7729L51.6454 25.7934L44.4736 25.803C43.6187 25.8042 42.9567 25.0552 43.0628 24.207L43.0643 24.1949L45.0786 8.09186L45.0801 8.07999C45.1967 7.16513 45.9729 6.47809 46.8953 6.47351L58.164 6.45278L58.1479 6.47859C58.953 6.48005 59.6048 7.13303 59.6048 7.93803ZM62.4537 0.00699474L62.4545 0L62.2171 0.000553541C62.2041 0.000452897 62.191 0 62.1778 0H62.1778L62.1774 0.000654184L60.8441 0.0036735L44.4796 0.0256138C40.7973 0.0339673 37.6954 2.77827 37.2386 6.43179L37.2306 6.4957L34.8164 25.8072L34.8105 25.8545C34.3859 29.2484 37.0326 32.2464 40.4533 32.2464H40.4534H41.9072H42.0519H53.32C53.3925 32.2464 53.4639 32.2416 53.5344 32.2334L58.1515 32.2207H58.1515C61.8338 32.2124 64.9358 29.4681 65.3925 25.8146L67.8147 6.43919L67.8207 6.39148C68.2335 3.08951 65.7393 0.162993 62.4537 0.00699474Z"
                                fill={color}
                            />
                            <path
                                id="Fill 8"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M92.4247 19.364L91.8142 24.1545L91.8127 24.1664C91.696 25.0813 90.9198 25.7683 89.9974 25.7729L78.7288 25.7936L78.7449 25.7678L78.6706 25.7676C77.8303 25.7662 77.1817 25.0283 77.2879 24.1949L79.3401 8.09186L79.3417 8.07998C79.4583 7.16513 80.2345 6.47809 81.1569 6.47351L85.2474 6.45298L92.4094 6.47859L92.4837 6.47874C93.324 6.48025 93.9727 7.21807 93.8664 8.0515L93.4508 11.3125H101.467L102.076 6.43919L102.082 6.39194C102.507 2.99798 99.8602 0 96.4394 0H83.5727C83.5002 0 83.4288 0.00473026 83.3583 0.0130334L78.7412 0.0256138C75.0619 0.0256138 71.9584 2.76514 71.5022 6.41564L71.4922 6.4957L69.078 25.8072L69.072 25.8549C68.6592 29.1569 71.1534 32.0834 74.4391 32.2394L74.4382 32.2464L74.6756 32.2458C74.6887 32.2459 74.7018 32.2464 74.7149 32.2464H74.715L74.7153 32.2457L76.0487 32.2427L92.4131 32.2207C96.0954 32.2124 99.1974 29.4681 99.6541 25.8146L99.6621 25.7507L100.461 19.364H92.4247Z"
                                fill={color}
                            />
                            <path
                                id="Fill 11"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M119.542 14.272L136.052 0H126.39L113.689 11.2721L115.107 0.0020632L108.674 0.000352253C107.754 0.000150966 106.978 0.685082 106.864 1.59782L106.863 1.6103L103.239 30.5957L103.237 30.6081C103.131 31.4566 103.793 32.206 104.648 32.206H111.081L112.885 17.7133L123.959 32.206H133.629L119.542 14.272Z"
                                fill={color}
                            />
                        </g>
                        <g id="FINANCIAL">
                            <path
                                id="F"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M87.7502 43.52H87.103L88.2425 37.033H92.0437L91.9435 37.6102H88.7894L88.3883 39.8733H91.3145L91.2233 40.4322H88.2972L87.7502 43.52Z"
                                fill={color}
                            />
                            <path
                                id="I"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M92.7639 43.52H92.1167L93.2562 37.033H93.9034L92.7639 43.52Z"
                                fill={color}
                            />
                            <path
                                id="N"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M99.0993 42.5946H99.1176L100.093 37.033H100.74L99.6007 43.52H98.7894L96.1458 37.8851H96.1275L95.1431 43.52H94.4958L95.6353 37.033H96.4466L99.0993 42.5946Z"
                                fill={color}
                            />
                            <path
                                id="A"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M102.673 41.2111H105.499L104.824 37.766L102.673 41.2111ZM101.241 43.52H100.494L104.651 37.033H105.289L106.629 43.52H105.945L105.599 41.8158H102.299L101.241 43.52Z"
                                fill={color}
                            />
                            <path
                                id="N_2"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M112.272 42.5946H112.29L113.265 37.033H113.912L112.773 43.52H111.962L109.318 37.8851H109.3L108.315 43.52H107.668L108.808 37.033H109.619L112.272 42.5946Z"
                                fill={color}
                            />
                            <path
                                id="C"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M119.663 42.3865C119.542 42.5435 119.4 42.6903 119.238 42.8268C119.076 42.9635 118.892 43.0827 118.687 43.1843C118.482 43.2861 118.256 43.3674 118.01 43.4285C117.765 43.4895 117.497 43.52 117.208 43.52C116.797 43.52 116.423 43.456 116.085 43.3282C115.746 43.2004 115.456 43.0202 115.213 42.7876C114.97 42.5552 114.782 42.2762 114.649 41.9506C114.516 41.6251 114.449 41.2647 114.449 40.8694C114.449 40.3347 114.545 39.8348 114.736 39.3697C114.927 38.9047 115.188 38.4993 115.521 38.1534C115.853 37.8076 116.242 37.5343 116.688 37.3338C117.133 37.1333 117.61 37.033 118.119 37.033C118.344 37.033 118.563 37.0563 118.774 37.1027C118.985 37.1493 119.18 37.2147 119.359 37.2989C119.539 37.3833 119.699 37.4864 119.841 37.6084C119.982 37.7305 120.097 37.8672 120.183 38.0182L119.62 38.3932C119.573 38.2944 119.505 38.1956 119.416 38.0967C119.326 37.998 119.218 37.9093 119.09 37.8308C118.963 37.7523 118.817 37.6899 118.652 37.6433C118.488 37.5969 118.304 37.5736 118.102 37.5736C117.662 37.5736 117.259 37.6651 116.891 37.8482C116.524 38.0313 116.209 38.2726 115.946 38.5719C115.683 38.8713 115.477 39.2157 115.33 39.6051C115.182 39.9946 115.109 40.3957 115.109 40.8084C115.109 41.1107 115.155 41.3941 115.248 41.6585C115.34 41.9231 115.477 42.1526 115.66 42.3473C115.842 42.5421 116.064 42.696 116.328 42.8094C116.591 42.9228 116.896 42.9794 117.243 42.9794C117.613 42.9794 117.966 42.9053 118.301 42.7571C118.636 42.6089 118.937 42.3749 119.203 42.0552L119.663 42.3865Z"
                                fill={color}
                            />
                            <path
                                id="I_2"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M121.706 43.52H121.059L122.199 37.033H122.846L121.706 43.52Z"
                                fill={color}
                            />
                            <path
                                id="A_2"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M124.778 41.2111H127.604L126.93 37.766L124.778 41.2111ZM123.347 43.52H122.6L126.757 37.033H127.395L128.735 43.52H128.051L127.705 41.8158H124.405L123.347 43.52Z"
                                fill={color}
                            />
                            <path
                                id="L"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M130.503 42.9427H133.52L133.429 43.52H129.765L130.904 37.033H131.551L130.503 42.9427Z"
                                fill={color}
                            />
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_511_23092">
                    <rect width="136" height="43.52" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
