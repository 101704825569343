export function FailureSvg() {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="39.668" cy="39.668" r="36.3623" fill="#F8374A" />
            <mask
                id="mask0_38_2271"
                maskUnits="userSpaceOnUse"
                x="13"
                y="13"
                width="54"
                height="54"
            >
                <rect x="13.2227" y="13.2227" width="52.8906" height="52.8906" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_38_2271)">
                <path
                    d="M40.0002 56.2772C39.186 56.2772 38.5006 55.9901 37.9442 55.4158C37.3895 54.8416 37.1122 54.1661 37.1122 53.3892C37.1122 52.5732 37.3993 51.8878 37.9735 51.3332C38.546 50.7785 39.2215 50.5011 40.0002 50.5011C40.8144 50.5011 41.4998 50.7874 42.0562 51.3598C42.6109 51.934 42.8882 52.6105 42.8882 53.3892C42.8882 54.2034 42.6011 54.8887 42.0269 55.4452C41.4544 55.9998 40.7789 56.2772 40.0002 56.2772ZM37.3895 45.3891V22.6104H42.6109V45.3891H37.3895Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}
