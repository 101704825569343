import { SelectProps } from '@mui/material';
import { Control, Controller, useFormContext } from 'react-hook-form';

import { OptionType, SingleSelect } from './SingleSelect';

type ControlledSingleSelectProps<T extends string | string[]> = {
    label: string;
    defaultOption?: T;
    options: OptionType<string>[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    name: string;
    testId?: string;
    helperText?: string | undefined;
    disabled?: boolean;
    inputProps?: SelectProps;
};

// TODO DU-1557 change the name to CustomSelect
export function ControlledSingleSelect<T extends string | string[]>({
    control,
    defaultOption,
    disabled = false,
    helperText,
    inputProps,
    label,
    name,
    options,
    testId = 'controlledSingleSelect',
}: ControlledSingleSelectProps<T>) {
    const { setValue } = useFormContext();

    const onChangeHandler = (value: string | string[]) => {
        if (Array.isArray(value)) {
            setValue(
                name,
                value.filter((v) => !!v)
            );
        } else {
            setValue(name, value);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <SingleSelect
                    {...rest}
                    disabled={disabled}
                    defaultOption={defaultOption ?? ''}
                    id={name}
                    label={label}
                    options={options}
                    inputProps={{
                        inputRef: ref,
                        variant: 'outlined',
                        ...inputProps,
                    }}
                    onChange={onChangeHandler}
                    testId={testId}
                    helperText={helperText}
                    isError={!!error}
                    errorMessage={error?.message}
                />
            )}
        />
    );
}
